<script>

  import SectionHeader  from "./sectionHeader.svelte"
  import LetterElement  from "./letter.svelte"
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  let image

  export let age
  export let type = 'height'
  export let gender = 'male'
  export let description = 'lorem ipsum dolor sit amet'
  export let title = 'altezza'
  export let letter


  // $: imageMode = mode || "fullSize"

  $: imageAge = age || 'kids'


  $: image = `img/sizes/${imageAge}/${gender}/${type}.jpg`

  const getSection = () => {
    return document.querySelector('.ovs-sizes__fullSize_howTo')
  }
  const removeSection = () => {
    return document.querySelector('.ovs-sizes__half-sections')
  }

  const activeClass = 'ovs-sizes__show'
  const inactiveClass = 'ovs-sizes__hide'


  function hideHowTo()  {
    console.log('hidw hoe wto 2')
    dispatch('close', {
      text: 'Hello!'
    });
  }


</script>
<div class="ovs-sizes__fullSize_howTo">
  <div class="ovs-sizes__fullSize_howToText">
    <div class="ovs-sizes__fullSize_howToHeader">
      <SectionHeader on:close="{hideHowTo}" text="{title}" letter="{letter}" type="{type}" version="close"></SectionHeader>
    </div>
    <div class="ovs-sizes__fullSize_howToDescription">{@html description}</div>
  </div>
</div>

<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {

    &__fullSize_howTo {
      transition: opacity 0.25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      display: flex;
      border-top: 3px solid $veryLightGrey;
      //border-bottom: 1px solid $veryLightGrey;
      justify-content: flex-end;
      min-height: calc(100% - 4px);
      &:last-of-type {
        //border-bottom: none;
      }

      z-index: 0;
    }

    &__fullSize_howToImage {
      font-size: 0;
      width: 50%;

      img {
        width: 100%;
        min-height: getVw(250px);
        @include tabletPortrait {
          min-height: 276px;
        }
      }
    }

    &__fullSize_howToText {
      position: relative;
      width: calc(50% - 1px);
      //border-bottom: 1px solid $veryLightGrey;
      border-left: 1px solid $veryLightGrey;
      background-color: white;
      @include tabletPortrait {
      }
      top: 0;
      left: 0;
    }

    &__fullSize_howToHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin:  18.750px 15.750px  24px;

    }

    &__fullSize_howToTitle {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 9px;
      letter-spacing: 0.2em;
      line-height: 15px;
      margin-right: 15px;
    }

    &__fullSize_howToDescription {
      margin: 18.750px 16.875px  24px;
      font-size: 13px;
    }



  }

</style>
