<script>
  import LetterElement from './letter.svelte'

  export let text = ''
  export let type
  export let index
  export let letter
  export let version
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();


  function hideHowTo()  {
    console.log('hidw hoe wto 1')
    dispatch('close', {
      text: 'Hello!'
    });
  }

</script>

<div class="ovs-sizes__sectionTextLine">
  <LetterElement letter="{letter}"></LetterElement>
  <span class="ovs-sizes__sectionMeasure">{text}</span>

  {#if version == "close"}

    <div class="ovs-sizes__fullSize_howToClose" on:click="{hideHowTo}"></div>
  {:else if text != 'anni'}
    <div class="ovs-sizes__fullSize_howToOpen"
         onClick="window.ovsSizeShowHowTo(this.dataset['type'], this.dataset['index'])" data-type="{type}"
         data-index="{index}"> i
    </div>
  {/if}
</div>


<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {

    &__sectionMeasure {
      letter-spacing: 0.2em;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 9px;
      line-height: $letterHeight;
      margin-left: 5px;
    }

    &__fullSize_howToOpen {
      right: 18.750px;
      top: 16.875px;
      font-size: 9px;
      cursor: pointer;
      position: absolute;
      border: 1px solid black;
      padding: 0;
      height: 14px;
      width: 14px;
      text-align: center;
      line-height: 14px;
      border-radius: 50%;
    }

    &__fullSize_howToClose {
      /*border: 1px solid red;
      border-radius: 50%;*/
      right: 18.750px;
      top: 16.875px;
      cursor: pointer;
      height: 14px;
      width: 14px;
      position: absolute;
      background-size: 9px;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='9px' height='9px' viewBox='0 0 9 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E8AEA5CD0-9F4C-46A4-B041-B23B3A101CE1@3x%3C/title%3E%3Cg id='M---Product-Detail-Page' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='guida_taglie---bambino---v2---tip' transform='translate(-350.000000, -375.000000)' fill='%23151515' fill-rule='nonzero'%3E%3Cg id='Combined-Shape' transform='translate(350.000000, 375.000000)'%3E%3Cpath d='M8.85735706,0.142642944 C9.04754765,0.332833537 9.04754765,0.641193413 8.85735706,0.831384005 L5.189,4.5 L8.85735706,8.16861599 C9.02377382,8.33503276 9.04457592,8.59192289 8.91976334,8.780958 L8.85735706,8.85735706 C8.66716646,9.04754765 8.35880659,9.04754765 8.16861599,8.85735706 L8.16861599,8.85735706 L4.5,5.189 L0.831384005,8.85735706 C0.641193413,9.04754765 0.332833537,9.04754765 0.142642944,8.85735706 C-0.0475476481,8.66716646 -0.0475476481,8.35880659 0.142642944,8.16861599 L3.811,4.5 L0.142642944,0.831384005 C-0.023773824,0.664967237 -0.0445759201,0.408077111 0.0802366561,0.219042003 L0.142642944,0.142642944 C0.332833537,-0.0475476481 0.641193413,-0.0475476481 0.831384005,0.142642944 L0.831384005,0.142642944 L4.5,3.811 L8.16861599,0.142642944 C8.35880659,-0.0475476481 8.66716646,-0.0475476481 8.85735706,0.142642944 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
</style>
