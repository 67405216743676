<script>
  import * as localConf from '../configuration.json'

  export let image
  let basePath = "img/sizes/"
  let env = `targetEnv`
  if (env === 'prod') {
    basePath = localConf.imagePath
    console.log('now in prod, serving images from ' + basePath)
  } else {
    console.log('not in prod, now in ' + `targetEnv` + ' serving local images')
  }

  $: imagePath = basePath + image
</script>
<img src="{ imagePath }"  loading="lazy" alt="">

<style type="text/scss">
  @import "./../scss/base";

</style>
