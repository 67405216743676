<script>
  export let type = 'title'
</script>

{#if type === 'sub'}
  <div class="ovs-sizes__subtitleContainer">
    <h4 class="ovs-sizes__subtitle"><slot></slot></h4>
  </div>
{:else if type === 'sub-central'}
  <div class="ovs-sizes__subtitleContainer ovs-sizes--upper">
    <h3 class="ovs-sizes__subtitle ovs-sizes--central"><slot></slot></h3>
  </div>
{:else}
  <div class="ovs-sizes__subtitleContainer">
    <h3 class="ovs-sizes__title"><slot></slot></h3>
  </div>
{/if}

<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {
    &__titleContainer {
    }
    &__title {
      padding: 0 40px;
      margin: 28px 0;
      font-family: $fontBarlow;
      font-weight: normal;
      font-size: 24px;
      text-align: center;
      color: $standardTextColour;
      @include tabletLandscape {
      }
    }
    &__subtitle {
      padding: 0 25px;
      margin: 12px  0;
      font-family: $fontBarlow;
      font-weight: normal;
      font-size: 13px;
      text-align: left;
      color: $standardTextColour;
    }
    &--central {
      text-align: center;
    }
    &--upper {
      margin-top: -1vw;
      margin-bottom: 4vw;
      @include tabletLandscape {
        margin-bottom: 0;
      }
    }
  }
</style>
