<script>
  import CategoryImage from "./categoryImage.svelte"

  let image

  export let range
  export let mode
  export let age
  export let type = 'title'
  export let text = 'petto'
  export let size = '0 cm'
  export let gender = 'female'
  export let description = 'lorem ipsum dolor sit amet'

  $: imageRange = range || "range-1"

  $: imageMode = mode || "sections"

  $: imageAge = age || "adult"

  $: image = `${imageAge}/${gender}/${imageRange}/${type}_big.jpg`

</script>

<div class="ovs-sizes__howTo">
  <div class="ovs-sizes__howToText">
    <p class="ovs-sizes__howToTitle">{text}</p>
    <p class="ovs-sizes__howToDescription">{@html description}</p>
  </div>
  <div class="ovs-sizes__howToImage">
    <CategoryImage image="{image}"></CategoryImage>
  </div>
</div>

<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {
    &__howTo {
      position: relative;
      border-bottom: 4px solid $white;
      margin-top: 15px;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__howToImage {
      position: relative;
      width: 100%;
      font-size: 0;
      :global(img) {
        width: 100%;
        min-height: getVw(250px);
        @include tabletPortrait {
          min-height: 276px;
        }
      }
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba($white, 0.5);
      }
    }
    &__howToText {
      padding: getVw(25px) getVw(25px) getVw(14px);
      @include tabletPortrait {
        padding: 25px 25px 18px;
      }
      //position: absolute;
      top: 0;
      left: 0;
      padding-left: 10%;
    }
    &__howToTitle {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.2em;
      line-height: 1;
    }
    &__howToDescription {
      margin:  15px 0 7px;
      font-size: 13px;
    }

  }
</style>
