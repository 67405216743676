<script>
  export let letter
</script>

<span class="ovs-sizes__measure-letter"><span>{letter}</span></span>


<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {
    &__measure-letter {
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      width: $letterHeight;
      height: $letterHeight;
      line-height: #{$letterHeight - 1px};
      border: 1px;
      border-radius: 50%;
      padding: 0;
      background-color: rgb(226, 226, 226);
      bottom: 0.1vw;
      position: relative;
      margin-right: 0.1vw;
      letter-spacing: 0;

      span {
        text-transform: uppercase;
        font-size: 7px;
        font-weight: bold;
        @include tabletPortrait {
        }
      }

    }

  }

</style>

