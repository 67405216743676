<script>
  import SectionHeader  from "./sectionHeader.svelte"
  import CategoryImage from "./categoryImage.svelte"
  let image

  export let range
  export let mode
  export let age
  export let type = 'title'
  export let text = 'petto'
  export let size = '0 cm'
  export let gender = 'female'
  export let letter
  export let index
  export let hasImg

  $: imageRange = range || "range-1"

  $: imageMode = mode || "sections"

  $: imageAge = age || "adult"

  $: image = `${imageAge}/${gender}/${imageRange}/${type}_small.jpg`

</script>

<div class="ovs-sizes__section">
  {#if imageMode === 'sections'}
    <div class="ovs-sizes__sectionImage">
      {#if hasImg }
        <CategoryImage image="{image}"></CategoryImage>
      {/if}
    </div>
    <div class="ovs-sizes__sectionText">
      <span class="ovs-sizes__sectionMeasure">{text}</span>
      <p class="ovs-sizes__sectionValue">{size}</p>
    </div>
  {:else if imageMode === 'fullSize'}
    <div class="ovs-sizes__sectionText" onclick="window.ovsSizeShowHowTo(this.dataset['type'], this.dataset['index'])" data-type="{type}" data-index="{index}">
      <SectionHeader letter="{letter}" type="{type}" text="{text}" index="{index}"></SectionHeader>
      <p class="ovs-sizes__sectionValue">{size}</p>
    </div>
  {:else if imageMode === 'slider'}
    <div class="ovs-sizes__slider-sectionText">
      <div class="ovs-sizes__sectionMeasure-slider">{text}</div>
      <div class="ovs-sizes__sectionValue-slider">{size}</div>
    </div>
  {/if}
</div>

<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {
    &__section {
      display: flex;
      border-bottom: 4px solid $white;
      position: relative;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__sectionImage {
      width: 33%;
      font-size: 0;
      :global(img) {
        width: 100%;
        min-height: getVw(85px);
        @include tabletPortrait {
          min-height: 94px;
        }
      }
    }
    &__sectionText {
      padding-left: 16.875px ;
      padding-top: 18.750px;
      cursor: pointer;
      @include noSelectHighlight;
    }
    &__sectionTextLine {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &__sectionMeasure {
      letter-spacing: 0.2em;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 9px;
      line-height: $letterHeight;
      margin-left: 5px;
      &-slider {
        letter-spacing: 0.2em;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 9px;
        margin-left: 18.750px;
        width: 25%;
        padding-top: 18px;
        @include tabletPortrait {
        }

      }
    }
    &__sectionValue {
      margin: 8px 0 0;
      font-size: 30px;
      line-height: 1;
      &-slider {
        font-size: 30px;
        line-height: 1;
        margin-left: 32px;

      }
    }
    &__measure-letter {
      text-transform: uppercase;
      font-size: 1.5vw;
      font-weight: bold;
      border: 1px;
      border-radius: 50%;
      padding: 0.1vw 0.8vw;
      background-color: rgb(226, 226, 226);
      bottom: 0.1vw;
      position: relative;
      margin-right: 0.1vw;
      @include tabletPortrait{
        font-size: 0.6vw;
        padding: 0.1vw 0.2vw;
      }
    }


    &__slider-sectionText{
      margin-top: 18.750px;
      margin-bottom: 18.750px;
      width: 100%;
      display: flex;
      @include tabletPortrait {
      }
    }

  }
</style>
