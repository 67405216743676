<script>
    // imports
    import {getContext, onMount} from 'svelte'
    import Title from './title.svelte'
    import Section from './section.svelte'
    import HowTo from './fullSize_howTo.svelte'
    import {currentSizeStore} from '../stores/stores'
    import { createEventDispatcher } from 'svelte';
    import CategoryImage from "./categoryImage.svelte"

    console.log('mode fullSize is on')

    const dispatch = createEventDispatcher();

    let currentSize
    let currentSize2
    const sizeStoreSub = currentSizeStore.subscribe(value => {
        currentSize = value;
    });

    const sizeStoreSub2 = currentSizeStore.subscribe(value => {
         currentSize2 = value;
    });

    // props
    export let currentCategory = undefined

    // vars and computed properties
    const getI18n = getContext('getI18n')
    const I18nReplace = getContext('I18nReplace')

    function changeSize(size) {
        console.log('change size', size)
        currentSizeStore.set(size)
        closeHowTo()
    }

    $: cellNumberRemain = currentCategory?.sizes.length % 6
    $: otherCells = cellNumberRemain > 0 ? (6 - cellNumberRemain) : 0

    let currentSizeData, correspondingSizesText

    onMount(() => {

    })

    $: currentSizeData = currentSize ? currentCategory?.sizes.find(o => {
        return o.code === currentSize.toString()
    }) : {}

    $: currentSizeData2 = currentSize ? currentCategory?.sizes2.find(o => {
      return o.code === currentSize.toString()
    }) : {}



    console.log('currentSizeData2 '+currentSizeData2);

    $: age = currentCategory?.age
    $: gender = currentCategory?.gender
    $: weight = currentCategory?.weight
    $: kindClothing = currentCategory?.kindClothing
    $: kindClothing2 = currentCategory?.kindClothing2

    $: imageAge = age

    $: image = `${imageAge}/${gender}/measures.jpg`

    const activeClass = 'ovs-sizes__show'
    const inactiveClass = 'ovs-sizes__hide'
    const getSection = () => { return document.querySelector('.ovs-sizes__fullSize_howTo')};
    const removeSection = () => { return document.querySelector('.ovs-sizes__half-sections')};

    let measureId
    let howToIndex
    const openHowTo = function (buttonId, index) {
        console.log('opened');
        window.requestAnimationFrame(() => {
            const howToSection = getSection();
            setTimeout(() => howToSection.classList.add(activeClass));
        })
        window.requestAnimationFrame(() => {
            const mainSection = removeSection();
            setTimeout(() => mainSection.classList.add(inactiveClass));
        })
        measureId = buttonId
        howToIndex = index
    }
    const closeHowTo = function () {
        console.log('closed')
        window.requestAnimationFrame(() => {
            const howToSection = getSection()
            setTimeout(() => howToSection.classList.remove(activeClass))
        })
        window.requestAnimationFrame(() => {
            const measuresSection = removeSection()
            setTimeout(() => measuresSection.classList.remove(inactiveClass))
        })
    }

    window.ovsSizeShowHowTo = openHowTo

    let mLetter = ['a', 'b', 'c', 'd', 'e']

    let howToLetter

</script>

<Title type="sub">{getI18n('instructions-age')}</Title>
<div class="ovs-sizes__selector">
    {#each currentCategory.sizes as size }
        <div class="ovs-sizes__selectorItem " on:click={() => changeSize(size.code)}>
            <div
                    class="ovs-sizes__selectorItemContainer {size.code === currentSize ? 'ovs-sizes__selectorItemContainer--current' : ''}">
                <span>{ size.code }</span>
            </div>
        </div>
    {/each}
    {#if otherCells > 0 }
        {#each Array(otherCells) as _, size }
            <div class="ovs-sizes__selectorItem ovs-sizes__selectorItem--empty">
            </div>
        {/each}
    {/if}
</div>

    {#if currentSize && currentSizeData && !currentCategory?.kindClothing && !currentCategory?.kindClothing2}
        <div class="ovs-sizes__fullSize">
            <div class="ovs-sizes__fullSizeContent">
                <div class="ovs-sizes__sectionImage">
                    <CategoryImage image="{image}"></CategoryImage>
                </div>
                <div class="ovs-sizes__half-sections">
                    {#each Object.entries(currentSizeData.measures) as [key, measure], i }
                        <Section  gender="{currentCategory.gender}" text={getI18n(key)} size={measure} range="{currentCategory.range}" mode="{currentCategory.mode}" age="{currentCategory.age}" letter ="{mLetter[i]}" type="{key}" index="{i}"></Section>
                        <span class="ovs-sizes__low-border"></span>
                    {/each}
                </div>
            </div>
            <HowTo on:close={closeHowTo} gender="{currentCategory.gender}" age="{currentCategory.age}" title="{getI18n(measureId)}" type="{measureId}" description="{getI18n('howToMeasure', measureId)}" letter ="{mLetter[howToIndex]}"></HowTo>
        </div>
    {/if}

    {#if currentSize && currentSizeData && currentCategory?.kindClothing}
        <div class="ovs-sizes__fullSize">
            <div class="ovs-sizes__fullSizeContent">
                {#if currentCategory.kindClothing}
                  <h3 style="width:100%; position:absolute; text-align:center; margin:2rem auto; font-wieght:300;">{currentCategory.kindClothing}</h3>
                  <div class="ovs-sizes__full-sections svelte-zw5e3w">
                      {#each Object.entries(currentSizeData.measures) as [key, measure], i }
                          <Section  gender="{currentCategory.gender}" text={getI18n(key)} size={measure} range="{currentCategory.range}" mode="{currentCategory.mode}" age="{currentCategory.age}" letter ="{mLetter[i]}" type="{key}" index="{i}"></Section>
                          <span class="ovs-sizes__low-border"></span>
                      {/each}
                  </div>
                {/if}
            </div>
            <HowTo on:close={closeHowTo} gender="{currentCategory.gender}" age="{currentCategory.age}" title="{getI18n(measureId)}" type="{measureId}" description="{getI18n('howToMeasure', measureId)}" letter ="{mLetter[howToIndex]}"></HowTo>
        </div>

        {#if currentSize && currentSizeData && currentCategory?.kindClothing2}
          <h3 style="width:100%; position:absolute; text-align:center; margin:2rem auto; font-wieght:300;">{currentCategory.kindClothing2}</h3>
          <div class="ovs-sizes__fullSize svelte-zw5e3w">
            <div class="ovs-sizes__full-sections svelte-zw5e3w">
                {#each Object.entries(currentSizeData2.measures) as [key, measure], i }
                    <Section  gender="{currentCategory.gender}" text={getI18n(key)} size={measure} range="{currentCategory.range}" mode="{currentCategory.mode}" age="{currentCategory.age}" letter ="{mLetter[i]}" type="{key}" index="{i}"></Section>
                    <span class="ovs-sizes__low-border"></span>
                {/each}
            </div>
          </div>
        {/if}



    {/if}

<style type="text/scss">
  @import "./../scss/base";

  :global(.ovs-sizes__fullSize_howTo.ovs-sizes__show) {
    opacity: 1 !important;
    z-index: 1 !important;
  }

  :global(.ovs-sizes__half-sections.ovs-sizes__hide) {
    opacity: 0 !important;
    z-index: 0 !important;
  }


  .ovs-sizes {

    $headerHeight: 68px;

    &__selector {
      border-top: 1px solid $veryLightGrey;
      display: flex;
      flex-wrap: wrap;
      font-size: 0;
    }

    &__selectorItem {
      width: calc(100% / 6);
      box-sizing: border-box;
      height: getVw(63px);
      font-size: 20px;
      border-bottom: 1px solid $veryLightGrey;
      border-right: 1px solid $veryLightGrey;
      cursor: pointer;
      user-select: none;

      @include tabletPortrait {
        height: 70px;
      }

      &:nth-child(6), &:last-of-type {
        border-right: none;
      }

      &Container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 2px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;

        &--current {
          font-weight: bold;
          border: 2px solid $black;
        }
      }

      &--empty {
        cursor: auto;
      }
    }

    &__fullSize {
      position: relative;
    }
    &__fullSizeContent {
      transition: opacity 0.25s ease-in-out;
      border-top: 3px solid $veryLightGrey;
      border-bottom: 1px solid $veryLightGrey;
      display: flex;
      position: relative;
      z-index: 1;
    }

    &__sectionImage {
      width: 50%;
      font-size: 0;
      margin: 0;
      :global(img) {
        width: 100%;
        min-height: getVw(85px);
        @include tabletPortrait {
          min-height: 94px;
        }
      }
    }

    &__description {
      padding: 25px;
      border-bottom: 1px solid $veryLightGrey;
      line-height: 1.2;
    }

    &__descriptionSimilar {
      font-size: 15px;
      padding-bottom:   12px;
      line-height: 1.2;

    }
    &__descriptionCorresponds {
      span {
        font-size: 13px;
      }
      p {
        font-size: 20px;
        margin: 5px 0;
      }
    }

    &__half-sections {
      width: 50%;
      border-left: 1px solid $veryLightGrey;
      transition: opacity 0.25s ease-in-out;
    }

    &__low-border {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $veryLightGrey;
      margin-top: 5px;
      &:last-of-type {
        background-color: $white;
      }
    }


  }

</style>
