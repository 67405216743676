<script>
    // imports
    import {getContext, onMount, afterUpdate} from 'svelte'
    import Title from './title.svelte'
    import Section from './section.svelte'
    import {currentSizeStore} from '../stores/stores'

    console.log('mode slider is on')

    let currentSize
    const sizeStoreSub = currentSizeStore.subscribe(value => {
        currentSize = value;
    });

    // props
    export let currentCategory = undefined
    // vars and computed properties
    const getI18n = getContext('getI18n')
    const I18nReplace = getContext('I18nReplace')

    export let configuration



    function changeSize(size) {
        console.log('click', window.ovsSizeIsDragging)
        if (!window.ovsSizeIsDragging) {
            console.log('change size', size)
            currentSizeStore.set(size)
        } else {
            console.log('isDragging, dont change size')
        }
    }

    let currentSizeData, correspondingSizesText

    onMount(() => {
        // console.log('on mount')
        const ele = document.getElementById('contSelector');
        ele.style.cursor = 'grab';

        let pos = { top: 0, left: 0, x: 0, y: 0 };



        const mouseDownHandler = (e) => {
            // console.log('mousedown')
            ele.style.cursor = 'grabbing';
            ele.style.userSelect = 'none';

            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()

            pos = {
                left: ele.scrollLeft,
                top: ele.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            ele.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = (e) => {
            // console.log('mousemove')
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            ele.scrollTop = pos.top - dy;
            ele.scrollLeft = pos.left - dx;

            window.ovsSizeIsDragging = true;

        };

        const mouseUpHandler = (e) => {
            // console.log('mouseup isDragging?', window.ovsSizeIsDragging, e)
            if (window.ovsSizeIsDragging) {
                e.preventDefault()
                e.stopPropagation()
                window.setTimeout(()=> window.ovsSizeIsDragging = false)
                // isDragging = false;
            }
            ele.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };


        // Attach the handler
        ele.addEventListener('mousedown', mouseDownHandler);
    })

    $: currentSizeData = currentSize ? currentCategory.sizes.find(o => {
        return o.code === currentSize.toString()
    }) : {}


    let selectorVar
    let itemBoxWidth
    let sizesArray
    let sizeIndex

    afterUpdate(() => {
        console.log('afterupdate')
        if (currentCategory) {

            selectorVar = document.querySelector('.ovs-sizes__containsSelector');

            itemBoxWidth = document.querySelector('.ovs-sizes__selectorItem').offsetWidth;

            sizesArray = currentCategory.sizes;

            if (currentSize) {

                sizeIndex = sizesArray.indexOf(currentSizeData);

                // selectorVar.scrollLeft = (sizeIndex - 1) * itemBoxWidth;
                let left = (sizeIndex - 1) * itemBoxWidth;
                selectorVar.scroll({
                    left: left,
                    top: 0,
                    behavior: 'smooth'
                })
                //console.log('scrolled-left', selectorVar.scrollLeft)
                //console.log('what?', sizeIndex, itemBoxWidth, sizesArray)
            } else {

                selectorVar.scrollLeft = 0;
                //console.log('scrolled-left is zero')
            }
        }
    });
    onMount( () => {

        console.log('mount')

    })

</script>

<Title type="sub-central">{getI18n('instructions-scroll')}</Title>
<div class="ovs-sizes__containsSelector" id="contSelector">
    <div class="ovs-sizes__selector">
        {#each currentCategory.sizes as size, i}
            <div class="ovs-sizes__selectorItem" on:click={() => changeSize(size.code)}>
                <div class="ovs-sizes__selectorItemContainer {size.code === currentSize ? 'ovs-sizes__selectorItemContainer--current' : ''}">
                    <div class="ovs-sizes__monthsValue">{ size.code }</div>
                    <div class="ovs-sizes__monthsText">{ getI18n(size.dataType) }</div>
                </div>
            </div>
        {/each}
    </div>
</div>
<div class="ovs-sizes__transCover"></div>
{#if currentSize && currentSizeData}
    <div class="ovs-sizes__full-sections">
        {#each Object.entries(currentSizeData.measures) as [key, measure] }
            <Section text="{getI18n(key)}" size="{measure}" mode="{currentCategory.mode}"></Section>
            <span class="ovs-sizes__low-border"></span>
        {/each}
    </div>
{/if}

<style type="text/scss">
  @import "./../scss/base";

  :global(.ovs-sizes__fullSize_howTo.ovs-sizes__show) {
    opacity: 1 !important;
    z-index: 1 !important;
  }

  :global(.ovs-sizes__fullSizeContent.ovs-sizes__hide) {
    opacity: 0 !important;
    z-index: 0 !important;
  }


  .ovs-sizes {

    $headerHeight: 68px;

    &__containsSelector {
      overflow: auto;
      width: 100%;
      height: 1000px;
      position: absolute;
      @include tabletPortrait {

      }
  }
    &__transCover {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: $headerHeight;
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%);
    }

    &__selectorMainView {
      position: relative;
      height: max-content;
    }

    &__selector {
      display: flex;
      font-size: 0;
      width: fit-content;
      width: -moz-fit-content;
      padding-left: 11.5vw;
      padding-right: 37vw;
      @include tabletPortrait {
        padding-left: 60px;
        padding-right: 165.5px;
      }
    }

    &__selectorItem {
      box-sizing: border-box;
      height: getVw(63px);
      font-size: 20px;
      cursor: pointer;
      user-select: none;
      width: 25.21vw;

      &:last-of-type {
      }

      @include tabletPortrait {
        height: 70px;
        width: 95px;
      }

      &:nth-child(6), &:last-of-type {
        border-right: none;
      }

      &Container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 2px solid $white;
        display: block;
        justify-content: center;
        align-items: center;
        color: rgb(120, 120, 120);
        @include tabletPortrait {
        }

        &--current {
          font-weight: bold;
          color: black;
        }
      }

      &--empty {
        cursor: auto;
      }
    }


    &__monthsValue {
      text-align: center;
      font-size: 20px;
      margin-top: 2vw;
      @include tabletPortrait {
        margin-top: 0.8vw;

      }
    }

    &__monthsText {
      text-transform: uppercase;
      text-align: center;
        font-size: 9px;
      @include tabletPortrait {
      }
    }

    &__full-sections {
      margin-top: 90px;
      border-top: 1px solid $veryLightGrey;
      border-bottom: 1px solid $veryLightGrey;
      width: 100%;
      @include tabletPortrait {
      }
    }

    &__low-border {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $veryLightGrey;
      &:last-of-type {
        background-color: $white;
      }
    }

  }

</style>
