<script>
  // imports
  import {getContext, onMount} from 'svelte'
  import Title from './title.svelte'
  import Section from './section.svelte'
  import HowTo from './howTo.svelte'
  import {currentSizeStore} from '../stores/stores'

  console.log('mode sections is on')

  let currentSize
  const sizeStoreSub = currentSizeStore.subscribe(value => {
    currentSize = value;
  });

  // props
  export let currentCategory = undefined
  // vars and computed properties
  const getI18n = getContext('getI18n')
  const I18nReplace = getContext('I18nReplace')

  function changeSize(size) {
    console.log('change size', size)
    currentSizeStore.set(size)
  }

  $: cellNumberRemain = currentCategory?.sizes.length % 6
  $: otherCells = cellNumberRemain > 0 ? (6 - cellNumberRemain) : 0
  $: hasImg = currentCategory.noImg ? false : true

  let currentSizeData, correspondingSizesText

  onMount(() => {

  })

  $: currentSizeData = currentSize ? currentCategory?.sizes.find(o => {
    return o.code === currentSize.toString()
  }) : {}
  let similarSizeText = ''
  $: if (currentSize && currentSizeData) {
    if (currentSizeData.similarSizes && currentSizeData.similarSizes.length > 0) {
      let baseString
      if (currentSizeData.similarSizes.length === 1) {
        baseString = ('sizeSimilarSize')
      } else {
        baseString = ('sizeSimilarSizes')
      }
      similarSizeText = I18nReplace(baseString, ['%size%', '%equivalent%'], [currentSize, currentSizeData.similarSizes.join(', ')])
    }
  }

  $: correspondingSizesText = currentSize ? currentSizeData?.correspondingSizes ? currentSizeData.correspondingSizes.join(' &nbsp;').replace(/(\d+)/g, '<strong>$1</strong>') : '' : ''

</script>
<Title type="sub">{getI18n('instructions-main')}</Title>
<div class="ovs-sizes__selector">
  {#each currentCategory.sizes as size }
    <div class="ovs-sizes__selectorItem " on:click={() => changeSize(size.code)}>
      <div
        class="ovs-sizes__selectorItemContainer {size.code === currentSize ? 'ovs-sizes__selectorItemContainer--current' : ''}">
        <span>{ size.code }</span>
      </div>
    </div>
  {/each}
  {#if otherCells > 0 }
    {#each Array(otherCells) as _, size }
      <div class="ovs-sizes__selectorItem ovs-sizes__selectorItem--empty">
      </div>
    {/each}
  {/if}
</div>
{#if currentSize && currentSizeData}
  <div class="ovs-sizes__sections">
    {#each Object.entries(currentSizeData.measures) as [key, measure] }
      <Section gender="{currentCategory.gender}" type={key} text={getI18n(key)} size={measure} range="{currentCategory.range}" mode="{currentCategory.mode}" age="{currentCategory.age}" hasImg="{hasImg}"></Section>
    {/each}
  </div>
  {#if currentSizeData.similarSizes || currentSizeData.correspondingSizes}
    <div class="ovs-sizes__description">
      {#if currentSizeData.similarSizes}
        <div class="ovs-sizes__descriptionSimilar">
          {@html similarSizeText}
        </div>
      {/if}
      {#if currentSizeData.correspondingSizes }
        <div class="ovs-sizes__descriptionCorresponds">
          <span>{ getI18n('correspondsTo') }</span>
          <p>{@html correspondingSizesText}</p>
        </div>
      {/if}
    </div>
  {/if}
  {#if currentCategory.howto !== false}
    <Title>{getI18n('howToMeasure', 'title')}</Title>
    <div class="ovs-sizes__howToContainer">
    {#each Object.entries(currentSizeData.measures) as [key, measure] }
      <HowTo gender="{currentCategory.gender}" type={key} text={getI18n(key)} description={getI18n('howToMeasure', key)} range="{currentCategory.range}" mode="{currentCategory.mode}" age="{currentCategory.age}"></HowTo>
    {/each}
    </div>
  {/if}
{/if}

<style type="text/scss">
  @import "./../scss/base";

  .ovs-sizes {
    &__selector {
      border-top: 1px solid $veryLightGrey;
      display: flex;
      flex-wrap: wrap;
      font-size: 0;
    }

    &__selectorItem {
      width: calc(100% / 6);
      box-sizing: border-box;
      height: getVw(63px);
      font-size: 20px;
      border-bottom: 1px solid $veryLightGrey;
      border-right: 1px solid $veryLightGrey;
      cursor: pointer;
      user-select: none;

      @include tabletPortrait {
        height: 70px;
      }

      &:nth-child(6), &:last-of-type {
        border-right: none;
      }

      &Container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 2px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;

        &--current {
          font-weight: bold;
          border: 2px solid $black;
        }
      }

      &--empty {
        cursor: auto;
      }
    }

    &__sections {
      border-top: 3px solid $veryLightGrey;
      border-bottom: 1px solid $veryLightGrey;
    }

    &__description {
      padding: 25px;
      border-bottom: 1px solid $veryLightGrey;
      line-height: 1.2;
    }

    &__descriptionSimilar {
      font-size: 15px;
      padding-bottom:   12px;
      line-height: 1.2;

    }
    &__descriptionCorresponds {
      span {
        font-size: 13px;
      }
      p {
        font-size: 20px;
        margin: 5px 0;
      }
    }

    &__howToContainer {
      margin-top: -20px;
    }

  }

</style>
