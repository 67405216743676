<script>
	import { onMount, setContext } from 'svelte';
	// todo read from json in HTML, not from configuration file
	import * as localConf from './configuration.json'
	import Mode_fullSize from './components/mode_fullSize.svelte'
	import Mode_sections from './components/mode_sections.svelte'
	import Mode_slider from './components/mode_slider.svelte'
	import Mode_mounting from './components/mode_mounting.svelte'
	import Title from './components/title.svelte'
	import {currentSizeStore} from './stores/stores'

	let configuration = {
		"language": "it"
	}

	const showLayerClass = 'ovs-sizes--active'
	let mainComponent = `mounting`
	let currentCategory = null
	let currentSize
	let storedCategory
	let notAvailableMsg
	let closeButton
	const sizeStoreSub = currentSizeStore.subscribe(value => {
		currentSize = value;
	});


	// todo load from properties
	export let testData = {
		"language": "en",
		"category": "Women-Jeans",
		"firstSize": "L",
		"selectedSize": "M",
		"productName": "Pantaloni crop svasati con cintura",
		"productImage": "https://www.ovs.it/dw/image/v2/AAKZ_PRD/on/demandware.static/-/Sites-ovs-it-master/default/dwb3d21315/images/hi-res/11636725.jpg"
	}


	const getContainer = () => { return document.querySelector('.ovs-sizes__container')};
	const getOverlay = () => { return document.querySelector('.ovs-sizes__overlay')};

	const closeOverlay = (e) => {
		console.log('eve', e, e.target, e.currentTarget, window.ovsSizeIsDragging)
		if (!window.ovsSizeIsDragging) {
			const container = getContainer();
			const overlay = getOverlay();
			overlay.classList.remove(showLayerClass)
			document.body.style.overflow = "auto";
			window.requestAnimationFrame(() => {
				setTimeout(() =>
						container.classList.remove(showLayerClass), 150);
			})
		}
	}

	const openOverlay = function (language, categoryID, firstSize, selectedSize, productName, productImage)   {
		console.log('clicked');
		const container = getContainer();
		console.log('container', container, showLayerClass)
		container.classList.add(showLayerClass)
		window.requestAnimationFrame(() => {
			document.body.style.overflow = "hidden";
			const overlay = getOverlay();
			setTimeout(() => overlay.classList.add(showLayerClass));
		})
		console.log("passing to initialize", language, categoryID, firstSize, selectedSize, productName, productImage)
		initialize (language, categoryID, firstSize, selectedSize, productName, productImage)
	}

	const initialize = (language, categoryID, firstSize, selectedSize, productName, productImage) =>  {
		console.log('receiving ', language, categoryID, firstSize, selectedSize, productName, productImage)

		let category
		// map categoryID -> category
		category = categoryID

		console.log('what category is passed', categoryID, category)


		// loop through categories to check which categories have this category ID
		let applicableCategories = localConf.sourceCategories[categoryID]

		// if size of applicableCategories > 1
		// check category using firstSize
		// if firstSize is literal, use "literal" category, otherwise use default
		console.log('what category is passed3', categoryID, category)



		if (applicableCategories){
			console.log('the category exists');

			let categoryStorage = applicableCategories.length

			// if size of applicableCategories === 0
			// throw error
			if (categoryStorage === 0) {
				console.log('the category exists but its empty');
			} else if (categoryStorage > 0){
				category = applicableCategories[0];
				if (categoryStorage === 1) {
					console.log('the category exists and has one subcategory only');

				} else if (categoryStorage > 1){
					console.log('the category exists and has more than one subcategories');

					let regExp = /[a-zA-Z]/;

					if (regExp.exec(firstSize)) {
						category = applicableCategories[1];
					}
				}

			}


		} else {
			console.log('the category doesnt exist');
		}



		configuration = {
			"language": language,
			"category": category,
			"firstSize": firstSize,
			"selectedSize": selectedSize,
			"productName": productName,
			"productImage": productImage
		}

		currentSizeStore.set(selectedSize)


		console.warn('cats', configuration, configuration.category,  localConf.categories)
		storedCategory = localConf.categories[configuration.category] || false
		if (!storedCategory.sizes || storedCategory.sizes.length === 0) {
			storedCategory = false
		}
		console.log('Is there a stored category?', storedCategory)
		currentCategory = storedCategory || configuration.category
		console.log('my current category', currentCategory)
		if (currentCategory) {
			mainComponent = `${currentCategory.mode}`
			console.warn('change main component', mainComponent)
			console.log('this is the stored category', category)
		}

		if (!currentCategory) {
			manageError (getI18n("errorMessages", "noCategory") + ' ' + configuration.category)
		} else {
		}
		mainTitle = getI18n('title')
		notAvailableMsg = getI18n("errorMessages", "notAvailable")
		closeButton = getI18n('close')
	}


	// expose main function
	window.ovsSizeShowOverlay = openOverlay

	window.ovsSizeLanguage = () =>  configuration.language
	window.ovsSizeIsDragging = false

	const getI18n = (item, sub = false) => {
		let translations = localConf.translations[window.ovsSizeLanguage()]
		if (sub) {
			if (translations[item]??[sub]) {
				return translations[item][sub]
			} else {
				return`${item}.${sub} not translated`
			}
		} else {
			if (translations[item]) {
				return translations[item]
			} else {
				return `${item} not translated`
			}
		}
	}
	let mainTitle = getI18n('title')

	const I18nReplace =  (key, toReplace = [], substitutes = []) => {
		console.log('start key', key)
		let srcString = getI18n(key)
		console.log('start string', srcString)
		let replacements = typeof(toReplace) === 'string' ? [toReplace] : toReplace
		let subs = typeof(substitutes) === 'string' ? [substitutes] : substitutes
		for (let i = 0; i < replacements.length; i++) {
			console.log('trying to replace ', replacements[i], 'with', subs[i])
			srcString = srcString.replace(new RegExp(replacements[i], 'g'), subs[i])
		}
		console.log('final string', srcString)
		return srcString
	}
	setContext('getI18n', getI18n)
	setContext('I18nReplace', I18nReplace)

	function manageError (msg) {
		console.error(`OVS Size error: ${msg}`, msg)
	}

	let modes = {
		"slider": Mode_slider,
		"mounting": Mode_mounting,
		"sections": Mode_sections,
		"fullSize": Mode_fullSize,
	}

	onMount( () => {
		if (window.ovsSizeTestMode) {
			window.ovsSizeShowOverlay(...Object.values(testData))
		}
		// only for DEV, with default data
		if (!isProduction) {
			openOverlay(testData.language, testData.category, testData.firstSize, testData.selectedSize, testData.productName, testData.productImage);
		}

	})

	$: getComponent = modes[mainComponent]

console.log
</script>

<div class="ovs-sizes ovs-sizes__container">
	<div class="ovs-sizes__overlay" on:click|self={closeOverlay}>
		<div class="ovs-sizes__content">
			<header class="ovs-sizes__header" on:click={closeOverlay}>
				{#if configuration.productName}
				<div class="ovs-sizes__headerProduct">
					<div class="ovs-sizes__headerImg"><img src="{ configuration.productImage }"></div>
					<div class="ovs-sizes__headerText"><p>{ configuration.productName }</p></div>
				</div>
				{/if}
				<div class="ovs-sizes__headerClose">

				</div>
			</header>
			{#if currentCategory}
			<Title>{ mainTitle }</Title>
			<div class="ovs-sizes__main">
				{#if storedCategory }
					<svelte:component this={getComponent} currentCategory="{currentCategory}"/>
				{:else}
					<div class="ovs-sizes__not-available">
						<div class="ovs-sizes__not-available_text">{notAvailableMsg}</div>
						<div class="ovs-sizes__not-available_close-button" on:click={closeOverlay}>{closeButton}</div>
					</div>
				{/if}
			</div>
			{/if}
		</div>
	</div>
</div>

<style type="text/scss">
	@import "./scss/base";

	/* global to avoid tree shaking */
	:global(.ovs-sizes__container.ovs-sizes--active) {
		display: block !important;
	}
	:global(.ovs-sizes__overlay.ovs-sizes--active) {
		  opacity: 1 !important;
		}
	:global(.ovs-sizes__overlay.ovs-sizes--active .ovs-sizes__content) {
			top: 0 !important;
	}

	.ovs-sizes {
		&__container {
			display: none;
			width: 100%;
			height: 100%;
			// defaults for all application
			font-family: $fontBarlow;
			font-size: 13px;
			line-height: 1.2;
			color: $standardTextColour;

			position: fixed;
			top: 0;
			left: 0;
			z-index: 1050;

			&--active {

			}
		}
		&__overlay {
			transition: opacity .15s ease-in;
			background-color: rgba(21,21,21,.2);
			height: 100%;
			overflow-y: scroll;
			opacity: 0;
			@include tabletPortrait {
				display: flex;
				align-items: flex-start;
				justify-content: center;
			}
		}
		$headerHeight: 68px;
		@mixin centeredElement {
			width: 100%;
			background: $white;
			@include tabletPortrait {
				width: 414px;
				max-width: 414px;
			}
		}
		&__content {
			@include centeredElement;
			min-height: 100%;
			position: relative;
			top: -5%;
			transition: top .15s ease-in;
			padding-top: $headerHeight;
			overflow: hidden;
			@include tabletPortrait {
				padding-top: 0;
				border-right: 1px solid $veryLightGrey;
				min-height: 100%;
			}

		}
		&__title {
			font-family: $fontBarlow;
			font-weight: normal;
			font-size: 24px;
			color: $standardTextColour;
		}
		&__header {
			@include centeredElement;
			cursor: pointer;
			height: $headerHeight;
			top: 0;
			left: 0;
			z-index: 1;
			position: fixed;
			display: flex;
			border-bottom: 1px solid $veryLightGrey;
			align-items: flex-end;
			justify-content: flex-end;
			@include tabletPortrait {
				position: static;
			}
		}
		&__headerProduct {
			display: flex;
			align-items: center;
			flex-grow: 1;
		}
		&__headerImg {
			font-size: 0;
			img {
				object-fit: cover;
				width: 48px;
				height: $headerHeight;
			}
		}
		&__headerText {
			padding-left: 15px ;
			p {
				margin: 0;
			}
		}
		&__headerClose {
			height: $headerHeight;
			width: $headerHeight;
			background-size: 15px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='9px' height='9px' viewBox='0 0 9 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E8AEA5CD0-9F4C-46A4-B041-B23B3A101CE1@3x%3C/title%3E%3Cg id='M---Product-Detail-Page' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='guida_taglie---bambino---v2---tip' transform='translate(-350.000000, -375.000000)' fill='%23151515' fill-rule='nonzero'%3E%3Cg id='Combined-Shape' transform='translate(350.000000, 375.000000)'%3E%3Cpath d='M8.85735706,0.142642944 C9.04754765,0.332833537 9.04754765,0.641193413 8.85735706,0.831384005 L5.189,4.5 L8.85735706,8.16861599 C9.02377382,8.33503276 9.04457592,8.59192289 8.91976334,8.780958 L8.85735706,8.85735706 C8.66716646,9.04754765 8.35880659,9.04754765 8.16861599,8.85735706 L8.16861599,8.85735706 L4.5,5.189 L0.831384005,8.85735706 C0.641193413,9.04754765 0.332833537,9.04754765 0.142642944,8.85735706 C-0.0475476481,8.66716646 -0.0475476481,8.35880659 0.142642944,8.16861599 L3.811,4.5 L0.142642944,0.831384005 C-0.023773824,0.664967237 -0.0445759201,0.408077111 0.0802366561,0.219042003 L0.142642944,0.142642944 C0.332833537,-0.0475476481 0.641193413,-0.0475476481 0.831384005,0.142642944 L0.831384005,0.142642944 L4.5,3.811 L8.16861599,0.142642944 C8.35880659,-0.0475476481 8.66716646,-0.0475476481 8.85735706,0.142642944 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		}
		&__main {
			position: relative;
			//overflow-y: hidden;
		}
		&__not-available{
			text-align: center;
			&_close-button {
				text-transform: uppercase;
				font-weight: bold;
				border: 1px solid black;
				border-radius: 1px;
				padding: 7px;
				width: 94px;
				margin: 20px auto 0;
				letter-spacing: .025em;
				transition: all .2s ease-out;
				&:hover {
					cursor: pointer;
					background-color: black;
					color: white;
				}
			}
		}
	}
</style>
